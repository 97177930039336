import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import { auth } from "../firebase";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  {
    path: "/docs",
    name: "Docs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Docs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: function() {
  //     return import("../views/Login.vue");
  //   },
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "booking",
        components: {
          default: () => import("../views/Booking.vue"),
          // section: () => import("../views/ViewBooking.vue"),
        },
      },
      {
        path: "files",
        components: {
          default: () => import("../views/Files.vue"),
          // section: () => import("../views/ViewBooking.vue"),
        },
      },
      {
        path: "clients",
        components: {
          default: () => import("../views/Clients.vue"),
          // section: () => import("../views/ViewBooking.vue"),
        },
      },
      {
        path: "offerings",
        components: {
          default: () => import("../views/Offerings.vue"),
          // section: () => import("../views/ViewBooking.vue"),
        },
      },
      {
        path: "team",
        components: {
          default: () => import("../views/Team.vue"),
          // section: () => import("../views/ViewBooking.vue"),
        },
      },
      {
        path: "profile",
        components: {
          default: () => import("../views/Profile.vue"),
          // section: () => import("../views/ViewBooking.vue"),
        },
      },
      {
        path: "terminal",
        components: {
          default: () => import("../views/Terminal.vue"),
          // section: () => import("../views/ViewBooking.vue"),
        },
      },
      // {
      //   path: "upload",
      //   components: {
      //     page: () => import("../views/Booking.vue"),
      //     // section: () => import("../views/UploadBooking.vue"),
      //   },
      // },
    ],
  },
  {
    path: "/file/:collection/:path/:filename",
    name: "File",
    component: () => import("../views/ViewFile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next("/");
  } else {
    next();
  }
});

export default router;
