<template>
  <div id="app">
    <div class="bg">
      <img
        src="../assets/img/pexels-ali-pazani-2681751.webp"
        alt="image"
        loading="lazy"
      />
    </div>
    <div></div>
    <List el="xsm" class="login">
      <Text el="h2">Sign In</Text>
      <Btn el=" rnd" class="gSignIn" @click="$store.commit('googleSignIn')"
        >Google</Btn
      >
      <div v-if="login.error != ''" style="color: red">
        {{ login.error }}
      </div>
    </List>
  </div>
</template>

<script>
// @ is an alias to /src
// import router from "../router";
// import { auth } from "../firebase";


export default {
  components: {
  },
  data() {
    return {
      login: {
        username: "",
        password: "",
        error: "",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}
.login {
  max-width: 364px;
  margin-bottom: 60px;
  --bg-color: #fff;
  border-radius: 16px;
  padding: 45px;
  --gap: 24px;
  width: 100%;
  z-index: 2;
  // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 0 5px rgb(0 0 0 / 80%);
  align-self: center;
}
.microsoft-sign-in {
  --bg-color: #000;
  --hover-bg-color: #222;
  --color: #fff;
  font-weight: var(--font-medium);
  grid-gap: 8px;
  --height: 40px;
  /* border:1px solid hsla(0,0%,100%,1); */
  /* box-shadow:0 0 0 3px hsla(0,0%,100%,0.7); */
}
.microsoft-sign-in img {
  height: 20px;
}
#app {
  // background-image: linear-gradient(hsla(0, 0%, 0%, 0.5), hsla(0, 0%, 0%, 1)),
  //   url(../assets/img/bg-optimized.jpg);
  // background-size: cover, cover;
  // background-position: center, center;
  // background-repeat: no-repeat, no-repeat;
  height: 100%;
  width: 100%;
  position: fixed;
  display: grid;
  grid-template-columns: 50% 1fr 0px;
  background-color: #ffe8cc;
  // background-color: #f3f5fb;
  min-height: 500px;
  overflow: auto;
  background-image: url(../assets/img/pattern.svg);
  background-size: 300px;
}
#logo {
  width: 100%;
  height: 50px;
}
.logo-symbol,
.logo-letters {
  fill: #000;
}
label [el*="label"] {
  font-weight: bold !important;
}
.bg {
  overflow: hidden;
  width: 80%;
  height: 200%;
  position: fixed;
  top: 50%;
  left: -40%;
  border-radius: 0 100% 100%;
  transform: translateY(-50%);
}

.bg img {
  height: 100vh;
  min-width: 100vw;
  object-fit: cover;
  position: absolute;
  top: 50%;
  right: -200px;
  transform: translateY(-50%);
}
.gSignIn {
  --bg-color: #eee;
    --hover-bg-color: #fff;
    --color: #444;
    font-weight: var(--font-bold);
  grid-gap: 8px;
  --height: 40px;
  background-image: url(../assets/icons/google.webp);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: 10px center;
  transition: 0.3s;
  box-shadow: 0 0 0 3px transparent;
}
.gSignIn:hover{
  box-shadow: 0 0 0 3px;
}
@media (max-width: 800px) {
  #app {
    grid-template-columns: 1fr 340px 1fr;
  }
  .bg {
    width: 80%;
    height: 200%;
    top: 50%;
    left: -30%;
  }
  // .login {
  //   box-shadow: 0 10px 25px rgb(0 0 0 / 15%);
  // }
}
</style>
