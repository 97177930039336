import { createStore } from "vuex";
import router from "../router";
import "../firebase";
let startsWith = require("lodash/startsWith");
let findKey = require("lodash/findKey");

// import Assignments from "./Assignments";
// import Learning from "./Learning";
// import Recordings from "./Recordings";
import Products from "./Products";
import Services from "./Services";
import Users from "./Users";

import {
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";
import API from "../components/API";

const Store = {
  state: () => ({
    window: {
      width: 1366,
      timeout: null,
    },
    groups: {
      admin: [
        "wesley@burkedesigns.biz",
        "wesleyburkew2@gmail.com",
        "hazel@hccreates.com",
      ],
    },
    user: {
      displayName: "John Doe",
      email: "test@email.com",
    },
    layout: {
      header: {
        accountDropdown: false,
      },
      nav: {
        active: "modules",
        collapse: true,
        menu: {
          // manageContentLibrary:{
          //     label:'Content Library',
          //     icon:'dashboard',
          // },
          Booking: {
            label: "Booking",
            icon: "bookmark",
            path: "/dashboard/booking",
          },
          Offerings: {
            label: "Offerings",
            icon: "widgets",
            path: "/dashboard/offerings",
          },
          Clients: {
            label: "Clients",
            icon: "favorite",
            path: "/dashboard/clients",
          },
          Team: {
            label: "Talent",
            icon: "work",
            path: "/dashboard/team",
          },
          Files: {
            label: "Files",
            icon: "article",
            path: "/dashboard/files",
          },
          Terminal: {
            label: "Terminal",
            icon: "shopping_cart",
            path: "/dashboard/terminal",
          },
        },
      },
      section: {
        loading: false,
        active: "modules",
        collapse: true,
      },
    },
  }),
  mutations: {
    resizeWindowEvent(state) {
      if (state.window.timeout != null) {
        clearTimeout(state.window.timeout);
      }
      state.window.timeout = setTimeout(function () {
        state.window.width = window.innerWidth;
      }, 100);
    },
    toggleAccountDropdown(state) {
      state.layout.header.accountDropdown =
        !state.layout.header.accountDropdown;
    },
    toggleNavState(state) {
      state.layout.nav.collapse = !state.layout.nav.collapse;
      if (state.window.width < 600) {
        state.layout.section.collapse = true;
      }
    },
    collapseSection(state, val) {
      state.layout.section.collapse = val;
    },
    googleSignIn(state) {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          console.log(user);

          state.user.displayName = auth.currentUser.displayName;
          state.user.email = auth.currentUser.email;
          state.user.photoURL = auth.currentUser.photoURL;
          router.push("/dashboard/booking");

          const userData = await API.post("/auth/user/data");
          if (userData.success == true && userData.user == null) {
            API.post("/auth/user/create", { ...state.user, permissions: [] });
          }
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          console.log(email, errorMessage);
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    },
    signOut() {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch(() => {
          // An error happened.
        });
    },
    navTo(state, section) {
      if (state.window.width < 600) {
        state.layout.nav.collapse = !state.layout.nav.collapse;
      }
      state.layout.nav.active = section;
      router.push(state.layout.nav.menu[section].path);
      // this.layout.section.collapse = true;
    },
    activateNavPath(state) {
      let path = router.currentRoute.value.path;
      const key = findKey(state.layout.nav.menu, function (item) {
        return startsWith(path, item.path);
      });
      if (key != null) {
        state.layout.nav.active = key;
      }
    },
  },
  actions: {},
  // computed
  getters: {
    isAdmin: (state) => {
      return state.groups.admin.includes(state.user.email);
    },
    // doneTodos: state => {
    //   return state.todos.filter(todo => todo.done)
    // }
  },
};

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // const uid = user.uid;
    // router.push("/dashboard");
    vuex.state.main.user.displayName = user.displayName;
    vuex.state.main.user.email = user.email;
    vuex.state.main.user.photoURL = user.photoURL;
    // console.log(user);
  } else {
    // User is signed out
    router.push("/");
  }
});

const vuex = createStore({
  modules: {
    products: Products,
    services: Services,
    users: Users,
    main: Store,
  },
});

export default vuex;
