import { auth } from "@/firebase";
import dialog from "@/components/dialog.js";

// -----------------------------------
// Public interface
// -----------------------------------

const API = {
  async post(url, body) {
    try {
      const token = await auth.currentUser.getIdToken();
      const data = await fetch(
        // `http://localhost:57775${url}`,
        `https://hc-creates-api.burkedesigns.workers.dev${url}`,
        {
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          }),
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      const res = await data.json();
      if (res.success == false) {
        await dialog.title(res.msg).alert(res.error);
      }
      return res;
    } catch (error) {
      await dialog
        .title("Unknown Error")
        .alert(
          `Please check your internet connection and try again. Error details: ${error}`
        );
      console.error(`Error: ${error}`);
    }
    // return open(res);
  },
  async postJson(url, body) {
    try {
      const token = await auth.currentUser.getIdToken();
      const data = await fetch(
        // `http://localhost:57775${url}`,
        `https://hc-creates-api.burkedesigns.workers.dev${url}`,
        {
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${token}`,
          }),
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      const res = await data.json();
      if (res.success == false) {
        await dialog.title(res.msg).alert(res.error);
      }
      return res;
    } catch (error) {
      await dialog
        .title("Unknown Error")
        .alert(
          `Please check your internet connection and try again. Error details: ${error}`
        );
      console.error(`Error: ${error}`);
    }
    // return open(res);
  },
  async postStream(url, body) {
    try {
      const token = await auth.currentUser.getIdToken();
      const data = await fetch(
        // `http://localhost:57775${url}`,
        `https://hc-creates-api.burkedesigns.workers.dev${url}`,
        {
          headers: new Headers({
            Authorization: `${token}`,
          }),
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      return data;
    } catch (error) {
      await dialog
        .title("Unknown Error")
        .alert(
          `Please check your internet connection and try again. Error details: ${error}`
        );
      console.error(`Error: ${error}`);
    }
    // return open(res);
  },
  async postForm(url, data = null, file = null) {
    try {
      const token = await auth.currentUser.getIdToken();
      let formData = new FormData();
      if (data != null) formData.set("data", JSON.stringify(data));
      if (file != null) formData.set("file", file);
      const result = await fetch(
        // `http://localhost:57775${url}`,
        `https://hc-creates-api.burkedesigns.workers.dev${url}`,
        {
          headers: new Headers({
            Authorization: `${token}`,
          }),
          method: "POST",
          body: formData,
        }
      );
      const res = await result.json();
      if (res.success == false) {
        await dialog.title(res.msg).alert(res.error);
      }
      return res;
    } catch (error) {
      await dialog
        .title("Unknown Error")
        .alert(
          `Please check your internet connection and try again. Error details: ${error}`
        );
      console.error(`Error: ${error}`);
    }
    // return open(res);
  },
  async getToken() {
    const token = await auth.currentUser.getIdToken();
    return token;
  },
  async get(url) {
    try {
      const token = await auth.currentUser.getIdToken();
      const data = await fetch(
        // `http://localhost:57775${url}`,
        `https://hc-creates-api.burkedesigns.workers.dev${url}`,
        {
          headers: new Headers({
            Accept: "application/json",
            Authorization: `${token}`,
          }),
          method: "GET",
        }
      );
      const res = await data.json();
      if (res.success == false) {
        await dialog.title(res.msg).alert(res.error);
      }
      return res;
    } catch (error) {
      await dialog
        .title("Unknown Error")
        .alert(
          `Please check your internet connection and try again. Error details: ${error}`
        );
      console.error(`Error: ${error}`);
    }
    // return open(res);
  },
};

export default API;
