// import router from "@/router";
// import { reactive } from "vue";
import { db } from "@/firebase";
import {
  collection,
  query,
  // where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

// import dialog from '@/components/dialog.js'
import API from "@/components/API";

// class Product {
//   constructor(options = {}) {
//     let d = new Date();
//     this.createdOn = d;
//     this.lastUpdated = d;
//     this.active = false;
//     this.createdBy = auth.currentUser.uid;
//     this.tags = ["draft"];
//     this.type = "product"; // contact, gift card, etc...
//     this.name = "Draft Product";
//     this.description = "";
//     this.duration = ""; // in mins
//     this.cost = ""; // in dollars

//     // replace with deep clone later
//     Object.assign(this, options);
//   }
//   data() {
//     // replace with deep clone later
//     return Object.assign({}, this);
//   }
// }

const Data = {
  namespaced: true,
  state: () => ({
    list: {},
    unsubscribe: null,
    status: "loading",
  }),
  //   Methods
  mutations: {
    async loadList(state) {
      state.list = {};
      state.status = "loading";
      const q = query(
        collection(db, "products"),
        orderBy("createdOn", "desc")
        // where("status", "==", "publish")
      );
      if (state.unsubscribe != null) {
        state.unsubscribe();
      }
      state.unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          state.list[doc.id] = data;
        });
        state.status = "loaded";
      });
      // console.log(state.list);
    },
    async add(state, data) {
      let result = await API.post("/auth/products/create", data);
      if (result.success == true) {
        state.list[result.product.id] = result.product;
        console.log("Added Product", result);
      }
    },
    async update(state, data) {
      let result = await API.post("/auth/products/update", data);
      if (result.success == true) {
        state.list[result.product.id] = result.product;
        console.log("Updated Product", result);
      }
    },
    delete(state, id) {
      delete state.list[id];
    },
  },
  actions: {},
  //   Computed Values
  getters: {},
};

export default Data;
